import { Text, Title } from "@clipboard-health/ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useState } from "react";

import {
  bonusBreakdownExpandedLocalStorageKey,
  defaultWorkerToWorkplaceIncentive,
} from "../constants";
import { useWorkerToWorkplaceAffiliateExperiment } from "../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../types";
import { getWorkerToWorkplaceVariantName } from "../utils/getWorkerToWorkplaceVariantName";
import { WorkplaceReferralCard } from "./WorkplaceReferralCard/WorkplaceReferralCard";

export function BonusBreakdown() {
  const ldFlags = useCbhFlags();
  const workplaceReferralsConfig = ldFlags[CbhFeatureFlag.WORKPLACE_REFERRALS] ?? {};
  const workerToWorkplaceIncentive =
    workplaceReferralsConfig.workerToWorkplaceIncentive ?? defaultWorkerToWorkplaceIncentive;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  const [isExpanded, setIsExpanded] = useState(() => {
    const hasUserInteractedWithBonusBreakdown = localStorage.getItem(
      bonusBreakdownExpandedLocalStorageKey
    );
    const hasUserExpandedBonusBreakdown =
      hasUserInteractedWithBonusBreakdown?.toLocaleLowerCase() === "true";
    return hasUserInteractedWithBonusBreakdown ? hasUserExpandedBonusBreakdown : true;
  });

  return (
    <WorkplaceReferralCard>
      <CardContent
        sx={{
          paddingTop: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Accordion
          expanded={isExpanded}
          sx={{
            background: (theme) => theme.palette.grey[100],
          }}
          onChange={() => {
            setIsExpanded((previousIsExpanded) => {
              const newIsExpanded = !previousIsExpanded;
              localStorage.setItem(
                bonusBreakdownExpandedLocalStorageKey,
                newIsExpanded ? "true" : "false"
              );
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                action: WorkplaceReferralAction.BONUS_BREAKDOWN_CLICKED,
                component: WorkplaceReferralComponent.BONUS_BREAKDOWN_SECTION,
                variant: getWorkerToWorkplaceVariantName(
                  isWorkerToWorkplaceAffiliateProgramEnabled
                ),
                isExpanded: newIsExpanded,
              });
              return newIsExpanded;
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Title bold component="h2" variant="h4">
              {isWorkerToWorkplaceAffiliateProgramEnabled
                ? "What You’ll Earn"
                : "Referral Bonus Breakdown"}
            </Title>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingBottom: "24px",
            }}
          >
            <Stack spacing={2}>
              <Text>
                {isWorkerToWorkplaceAffiliateProgramEnabled
                  ? "This is what you can earn if a facility you refer signs up to Clipboard, once their first shift is verified."
                  : "You'll get the following bonuses when you successfully refer any of these facility types."}
              </Text>

              <Box
                sx={{
                  background: "white",
                }}
              >
                <List>
                  {Object.entries(workerToWorkplaceIncentive)
                    .sort(
                      (firstWorkplaceIncentive, secondWorkplaceIncentive) =>
                        secondWorkplaceIncentive[1] - firstWorkplaceIncentive[1]
                    )
                    .map(([workplaceType, incentive]) => (
                      <ListItem key={workplaceType}>
                        <ListItemText
                          primary={
                            <Stack direction="row" justifyContent="space-between" spacing={2}>
                              <Text>{workplaceType}</Text>
                              <Text>
                                {formatDollarsAsUsd(incentive, {
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                                })}
                              </Text>
                            </Stack>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </WorkplaceReferralCard>
  );
}
