import { Clipboard } from "@capacitor/clipboard";
import { Text } from "@clipboard-health/ui-react";
import { Box, Button, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { useWorkerToWorkplaceAffiliateExperiment } from "../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../types";
import { getWorkerToWorkplaceVariantName } from "../utils/getWorkerToWorkplaceVariantName";

interface CopyReferralCodeProps {
  referralCode: string;
}

export function CopyReferralCode(props: CopyReferralCodeProps) {
  const { referralCode } = props;
  const { showSuccessToast } = useToast();
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 2,
        paddingX: 2,
        backgroundColor: "white",
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Text>{referralCode}</Text>
        <Button
          size="small"
          onClick={async () => {
            await Clipboard.write({
              string: referralCode,
            });
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
              action: WorkplaceReferralAction.REFERRAL_CODE_COPIED,
              component: WorkplaceReferralComponent.COPY_REFERRAL_CODE_SECTION,
              variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
              referralCode,
            });
            showSuccessToast("Referral code copied successfully");
          }}
        >
          Copy
        </Button>
      </Stack>
    </Box>
  );
}
